<template>
  <div class="form-container">
    <h5 class="main-title">영상 등록</h5>
    <div class="info-flex">
      <div>
        <div class="bs-flex">
          <h5 class="info-title">상/하지 선택</h5>
          <mdb-select
              outline
              placeholder="선택"
              v-model="oneOptions"
              @getValue="getSelectOneValue"
              class="search-select"/>
          <h5 class="info-title" style="margin-left: 10px">평가도구 선택</h5>
          <mdb-select
              v-if="oneOptionVal === '상지'"
              outline
              v-model="ueOptions"
              @getValue="getSelectUlValue"
              class="search-select"/>
          <mdb-select
              v-if="oneOptionVal === '하지'"
              outline
              v-model="leOptions"
              @getValue="getSelectUlValue"
              class="search-select"/>
        </div>
        <div class="bs-flex">
          <h5 class="info-title">영상 순서 입력</h5>
          <input type="number" class="custom-input-box " placeholder="영상 순번을 입력해 주세요." v-model="orderNumber"/>
        </div>
        <div class="bs-flex">
          <h5 class="info-title">영상 제목 입력</h5>
          <input type="text" class="custom-input-box " placeholder="영상 제목을 입력해주세요." v-model="title"/>
        </div>
        <div class="bs-flex">
          <h5 class="info-title">영상 업로드 링크</h5>
          <input type="text" class="custom-input-box " placeholder="비메오에 등록한 영상 링크를 입력해주세요." v-model="url"/>
        </div>
        <div class="bs-flex">
          <h5 class="info-title">영상 재생 시간</h5>
          <input type="text" class="custom-input-box " placeholder="00:00" v-model="time"/>
        </div>
      </div>
    </div>
    <hr/>
    <div class="bs-flex">
      <button class="btn-list" @click="back">목록으로</button>
      <button class="btn-add" @click="boardAdd">등록</button>
    </div>
  </div>
</template>

<script>
import {mdbSelect} from "mdbvue";
import {firebase} from "@/firebase/firebaseConfig";
import {firebaseError} from "@/lib/firebaseError";

export default {
  name: "VideoAddPage",
  components: {
    mdbSelect,
  },
  data() {
    return {
      fbCollection: 'videos',
      oneOptions: [
        {text: "상지", value: "상지", selected: true},
        {text: "하지", value: "하지"},
      ],
      ueOptions: [
        {text: "FM", value: "Fugl-Meyer", selected: true},
        {text: "BB", value: "BB"},
        {text: "WMFT", value: "WMFT"},
        {text: "AAUT", value: "AAUT"},
        {text: "MAL", value: "MAL"},
        {text: "SIS", value: "SIS"},
      ],
      leOptions: [
        {text: "전체", value: "전체", selected: true},
        {text: "FM_LOW", value: "Fugl-Meyer-Low"},
        {text: "BBS", value: "BBS"},
        {text: "TUG", value: "TUG"},
        {text: "FTSTS", value: "FTSTS"},
        {text: "TMWT", value: "TMWT"},
        {text: "SMWT", value: "SMWT"},
        {text: "DGI", value: "DGI"},
        {text: "ABCscale", value: "ABCscale"},
      ],
      videoOptionVal: '교육 영상',
      oneOptionVal: '상지',
      ulOptionVal: 'Fugl-Meyer',
      orderNumber: 1,
      time: '',
      title: '',
      toolName: '',
      type: '',
      ul: '',
      url: '',
    }
  },
  methods: {
    getSelectOneValue(value) {
      this.oneOptionVal = value;
      if (value === '상지') {
        this.ulOptionVal = 'Fugl-Meyer';
      } else if (value === '하지') {
        this.ulOptionVal = '없음';
      }
    },
    getSelectUlValue(value) {
      this.ulOptionVal = value;
    },
    boardAdd() {
      const self = this;
      self.fbCollection = 'videos'

      const data = {
        orderNumber: self.orderNumber,
        title: self.title,
        type: self.videoOptionVal,
        time: self.time,
        toolName: self.ulOptionVal,
        ul: self.oneOptionVal,
        url: self.url,
      }

      firebase.firestore().collection(self.fbCollection)
          .add(data)
          .then(async () => {
            alert('등록되었습니다.');
            this.$router.go(-1)
          }).catch((err) => {
        firebaseError(err)
      });
    },

    back() {
      this.$router.go(-1)
    },
    cancel() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
.form-container {
  background: white;
  padding: 41px 38px;
  min-width: 900px;
}

.main-title {
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 30px;
}

.bs-flex {
  display: flex;
  flex-direction: row;
}

.info-flex {
  display: flex;
  flex-direction: row;
}

.info-flex .bs-flex {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
}

.info-title {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 40px;
  letter-spacing: 0.0015em;
  color: #272833;
  min-width: 150px;
  margin-right: 10px;
}

hr {
  position: relative;
  padding: 0 !important;
  margin-top: 12px;
  margin-bottom: 27px;
  border-top: 2px solid #E1E3E6;
}

/deep/ .caret {
  display: none;
}

.search-select {
  margin-right: 10px;
}

/deep/ .search-select .md-form {
  width: 237px !important;
  padding: 0 !important;
  margin: 0 !important;
  background: url('../../../assets/images/IA/icon_down.png') no-repeat right 9px center;
  background-size: 18px 18px;
}

/deep/ .search-select .form-control {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  height: 40px;
  letter-spacing: 0.0025em;
  color: #999999;
  margin: 0;
}

.w654 {
  width: 654px;
}

.w237 {
  width: 237px;
}

.btn-list {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
  width: 116px;
  height: 40px;
  background: #0A1120;
  border-radius: 4px;
  border: 0;
  margin-right: 10px;
}

.btn-cancel {
  margin-left: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
  width: 116px;
  height: 40px;
  background: #8D8D8D;
  border-radius: 4px;
  border: 0;
  margin-right: 10px;
}

.btn-add {
  margin-left: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
  width: 116px;
  height: 40px;
  background: #0069CA;
  border-radius: 4px;
  border: 0;
}

/deep/ .custom-input-box::placeholder {
  font-size: 16px !important;
}
</style>
